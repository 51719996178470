'use strict';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// Main navigation toggle

const mainNavToggle = document.querySelector('.main-nav-toggle');
const mainNav = document.querySelector('.main-nav');

mainNavToggle.addEventListener('click', function(){
  this.classList.toggle('is-active');
  mainNav.classList.toggle('is-open');
});

// Close update banner

const updateBanner = document.querySelector('.update-banner');
const closeUpdateBanner = document.querySelector('.close-update-banner');

closeUpdateBanner.addEventListener('click', function(){
  updateBanner.remove();
});

// Blog filters

const articleGridList = document.querySelector('.posts-feed--paginated');
const targetSelector = '.mix';

if (articleGridList) {
  const mixer = mixitup(articleGridList, {
    animation: {
      enable: false,
      animateResizeContainer: false
    },
    pagination: {
      limit: 9,
      hidePageListIfSinglePage: true,
      loop: true
    },
    selectors: {
      pageList: '.pagination',
      target: targetSelector
    },
    templates: {
      pagerPrev:
        '<button type="button" class="${classNames} pagination__pg-prev" data-page="prev" aria-label="Previous page"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" aria-label="Previous page"><path d="M74.163 44.695L29.111 1.097a3.965 3.965 0 0 0-5.475 0 3.67 3.67 0 0 0-.148 5.16q.072.076.148.148l45.027 43.59L23.834 93.4a3.845 3.845 0 0 0-.421 5.267 3.958 3.958 0 0 0 5.617.308l.081-.075 45.052-43.598 1.828-1.767a4.892 4.892 0 0 0 .186-6.88q-.09-.095-.186-.186z"/></svg></button>',
      pagerNext:
        '<button type="button" class="${classNames} pagination__pg-next" data-page="next" aria-label="Next page"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" aria-label="Next page"><path d="M74.163 44.695L29.111 1.097a3.965 3.965 0 0 0-5.475 0 3.67 3.67 0 0 0-.148 5.16q.072.076.148.148l45.027 43.59L23.834 93.4a3.845 3.845 0 0 0-.421 5.267 3.958 3.958 0 0 0 5.617.308l.081-.075 45.052-43.598 1.828-1.767a4.892 4.892 0 0 0 .186-6.88q-.09-.095-.186-.186z"/></svg></button>',
      pager:
        '<button type="button" class="${classNames} pagination__pg-number" data-page="${pageNumber}" aria-label="Go to ${pageNumber}">${pageNumber}</button>'
    }
  });
}

// Galleries

const imgGallery = document.querySelector('.img-gallery');

if(imgGallery) {

  const imgGallerySlides = tns({
    container: imgGallery,
    mode: 'gallery',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: false,
    nav: true,
    navContainer: '.img-gallery__nav'
  });

}

const tradespersonGallery = document.querySelector('.tradeperson-promo__info__slider');

if(tradespersonGallery) {

  const tradespersonGallerySlides = tns({
    container: tradespersonGallery,
    mode: 'gallery',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: false,
    nav: true,
    navContainer: '.tradesperson-promo__nav'
  });

}

// CF7 checkboxes

function validate() {
  var remember = document.getElementById("remember");
  if (remember.checked) {
    alert("checked");
  } else {
    alert("You didn't check it! Let me check it for you.");
  }
}

const cf7Labels = document.querySelectorAll('.wpcf7-list-item input');

cf7Labels.forEach(function (cf7Label) {
  if(cf7Label.checked) {
    console.log('checked');
  }
});

// =================================================
// COOKIES
// =================================================

var dropCookie = true;                  // False disables Cookie for testing
var cookieDuration = 7;                // Number of days before cookie expires
var cookieName = 'ssAcceptedCookies';
var cookieValue = 'on';
 
function createDiv(){
    var bodytag = document.getElementsByTagName('body')[0];
    var div = document.createElement('div');
    div.setAttribute('id','cookies-banner');
    // Change href below if your cookie policy page link is not /cookies/
    div.innerHTML = '<p>We use cookies to give you the best experience on our website. By continuing, you agree to our <a href="/cookies-policy/" rel="nofollow" title="Cookie Policy">cookies policy</a>. <span><a class="close-cookie-banner" href="javascript:void(0);" onclick="removeMe();">I agree</a></span>.</p>';    
    bodytag.insertBefore(div,bodytag.firstChild); // Add the banner just after the opening <body> tag
    createCookie(window.cookieName,window.cookieValue, window.cookieDuration); // Create the cookie
}  
function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000)); 
        var expires = "; expires="+date.toGMTString(); 
    }
    else var expires = "";
    if(window.dropCookie) { 
        document.cookie = name+"="+value+expires+"; path=/"; 
    }
} 
function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
} 
function eraseCookie(name) {
    createCookie(name,"",-1);
} 
window.onload = function(){
    if(checkCookie(window.cookieName) != window.cookieValue){
        createDiv(); 
    }
}
function removeMe(){
    var element = document.getElementById('cookies-banner');
    element.parentNode.removeChild(element);
}

// Book hero form actions

const formSection1 = document.querySelector('.contact-form-section--1');
const formSection2 = document.querySelector('.contact-form-section--2');
const formSection3 = document.querySelector('.contact-form-section--3');

const nextStep1 = document.querySelector('.contact-form-section--1 .next-step');
const nextStep2 = document.querySelector('.contact-form-section--2 .next-step');
const nextStep3 = document.querySelector('.contact-form-section--3 .next-step');

const backStep1 = document.querySelector('.contact-form-section--2 .previous-step');
const backStep2 = document.querySelector('.contact-form-section--3 .previous-step');

if(nextStep1) {
  nextStep1.addEventListener('click', function(e){
    if(formSection2.style.display = "none") {
      formSection2.style.display = "block";
    }
    formSection2.scrollIntoView(); 
    e.preventDefault();
  });
}

if(nextStep2) {
nextStep2.addEventListener('click', function(e){
    if(formSection3.style.display = "none") {
      formSection3.style.display = "block";
    }
    formSection3.scrollIntoView(); 
    e.preventDefault();
  });
}

if(backStep1) {
  backStep1.addEventListener('click', function(e){
    formSection1.scrollIntoView(); 
    e.preventDefault();
  });
}

if(backStep2) {
  backStep2.addEventListener('click', function(e){
    formSection3.scrollIntoView(); 
    e.preventDefault();
  });
}